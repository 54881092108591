export default {
  name: 'oHamburger',
  methods: {
    toggleActive() {
      const el = document.body;

      el.classList.toggle('no-scroll');
    }
  }
};
