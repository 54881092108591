import mSideNavigation from '@/modules/m-side-navigation/m-side-navigation.vue'
import mHeader from '@/modules/m-header/m-header.vue';
import mNavigation from '@/modules/m-navigation/m-navigation.vue';
import mUserMenu from '@/modules/m-user-menu/m-user-menu.vue';
import oLogo from '@/objects/o-logo/o-logo.vue';
import oToast from '@/objects/o-toast/o-toast.vue';

export default {
  name: 'LayoutPrivate',

  components: {
    'm-side-navigation': mSideNavigation,
    'm-header': mHeader,
    'm-user-menu': mUserMenu,
    'm-navigation': mNavigation,
    'o-logo': oLogo,
    'o-toast': oToast
  },

  data() {
      return {
          expanded: true
      };
  },

  methods: {
    onNavigationExpanded:function(event) {
      this.expanded = event;
    }
  },

  mounted() {
      if(this.$cookie.get('sc_menu_expanded') != null) {
        this.expanded = (this.$cookie.get('sc_menu_expanded') == 'true');
      }
    }
};
