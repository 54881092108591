export default {
  name: 'oButtonExpand',

  props: {
    expanded: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onClicked: function() {
      this.$emit('clicked');
    }
  }
};
