import { Notification } from '@/utils/Notification';

export default {
  name: 'oToast',

  data() {
    return {
      queue: Notification.getQueue()
    };
  },

  methods: {
    close: (index) => {
      Notification.remove(index);
    },
    getType(toast) {
      switch (toast.type) {
        default:
        case Notification.TYPE.SUCCESS:
          return 'check';
        case Notification.TYPE.ERROR:
          return 'error';
        case Notification.TYPE.INFO:
          return 'info';
        case Notification.TYPE.WARNING:
          return 'warning';
      }
    }
  },


  mounted() {
  },

  updated() {
  }
};
