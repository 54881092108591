import ClickOutside from 'vue-click-outside';

export default {
  name: 'oDropdown',
  data() {
    return {
      isActive: false
    };
  },
  props: {
    label: String
  },
  methods: {
    hide() {
      this.isActive = false;
    }
  },
  directives: {
    ClickOutside
  }
};
