import oButton from '@/objects/o-button/o-button.vue';
import oHamburger from '@/objects/o-hamburger/o-hamburger.vue';
import oLogo from '@/objects/o-logo/o-logo.vue';

export default {
  name: 'mNavigation',
  components: {
    'o-button': oButton,
    'o-hamburger': oHamburger,
    'o-logo': oLogo
  },
  data() {
    return {
      isActive: false
    };
  },
  methods: {

  }
};
