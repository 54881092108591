import oButtonExpand from '@/objects/o-button-expand/o-button-expand.vue';
import oLogo from '@/objects/o-logo/o-logo.vue';

export default {
    name: "mNavigation",

    components: {
      'o-button-expand': oButtonExpand,
      'o-logo': oLogo
    },

    props: {

    },

    data() {
        return {
            expanded: true,
            logoType: 'small'
        };
    },

    methods: {
        toggle: function() {
          this.expanded = !this.expanded;
          this.$cookie.set('sc_menu_expanded', this.expanded, 7);
          this.$emit('expanded', this.expanded);
        },

        setLogoType: function() {
          if(!this.expanded) {
            this.logoType = 'small';
          }else{
            this.logoType = 'large';
          }

          if(window.innerWidth < 1200) {
            this.logoType = 'small';
          }
        }
    },

    computed: {
      logo_type: function() {
        this.setLogoType();
        return this.logoType;
      }
    },

    mounted(){
      window.addEventListener('resize', this.setLogoType);

      this.setLogoType();

      if(this.$cookie.get('sc_menu_expanded') != null) {
        this.expanded = (this.$cookie.get('sc_menu_expanded') == 'true');
      }
    }
};
