import oDropdown from '@/objects/o-dropdown/o-dropdown.vue';
import oList from '@/objects/o-list/o-list.vue';

export default {
  name: 'mUserMenu',
  components: {
    'o-dropdown': oDropdown,
    'o-list': oList
  },
  methods: {

  }
};
